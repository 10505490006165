import React, { useState } from "react";

// const baseURL = process.env.REACT_APP_FABRIC_BASEURL;
const transferURL = process.env.REACT_APP_FABRIC_TRANSFERURL;

const SendFunds = async (to, amount) => {
  try {
    let sendResponse = await fetch(transferURL + "RequestTransfer", {
      method: "POST",
      body: JSON.stringify({
        fabricUserName: sessionStorage.getItem("fabricUserName"),
        to: to,
        value: parseFloat(amount),
      }),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    });
    let response = await sendResponse.text();
    return response;
  } catch (err) {
    console.log(err);
  }
};

function Send() {
  const [to, setTo] = useState("");
  const [amount, setAmount] = useState();

  const sendBtn = async () => {
    if (amount < 0) {
      alert("Input a positive number");
    } else if (sessionStorage.getItem("fabricUserName") === to) {
      alert("No money duplication glitches here");
    } else if (amount > parseFloat(sessionStorage.getItem("userBalance"))) {
      alert("Not enough funds");
    } else {
      // if account does not exist this will still appear as a successful transfer but user will keep their funds
      // Needs another else if that prevents sending to a wallet that does not exist
      console.log(sessionStorage.getItem("fabricUserName"));
      await SendFunds(to, amount);
      console.log(`transaction completed, submitting to database...`);
      await submitCouchSender();
      // await submitCouchRecipient();
      console.log(`submition complete: transfering you back to home screen`);
      alert(`Transaction complete!`);
      window.location.assign("./Home");
    }
  };

  // const fetchBalance = async () => {
  //   try {
  //     const fabricResponse = await fetch(baseURL + "GetAccountBalance", {
  //       method: "POST",
  //       body: JSON.stringify({
  //         fabricUserName: sessionStorage.getItem("fabricUserName"),
  //       }),
  //       headers: {
  //         "Content-type": "application/json; charset=UTF-8",
  //       },
  //     });
  //     const balance = await fabricResponse.text();
  //     return balance;
  //   } catch (err) {
  //     console.log(err);
  //   }
  // };

  // Send fields to express endpoint
  const submitCouchSender = async () => {
    try {
      const response = await fetch("/temp/p2p_transactions/add", {
        method: "POST",
        body: JSON.stringify({
          tx_type: "Sent",
          name: sessionStorage.getItem("fabricUserName"),
          tx_amount: amount,
          to: to,
        }),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      });
      const couchData = await response.json();
      console.log(couchData);
    } catch (err) {
      console.log(err);
    }
  };

  // const submitCouchRecipient = async () => {
  //   try {
  //     const response = await fetch("/p2p_transactions/add", {
  //       method: "POST",
  //       body: JSON.stringify({
  //         tx_type: "Recieved",
  //         name: to,
  //         tx_amount: amount,
  //         to: to,
  //         from: sessionStorage.getItem("fabricUserName"),
  //       }),
  //       headers: {
  //         "Content-type": "application/json; charset=UTF-8",
  //       },
  //     });
  //     const couchData = response.json();
  //     console.log(couchData);
  //   } catch (err) {
  //     console.log(err);
  //   }
  // };

  return (
    <div className="send-page">
      <header className="send-header">
        <p>Send to</p>
      </header>

      <button
        className="cancel-send-btn"
        onClick={() => (window.location = "./Home")}
      >
        Back
      </button>

      <div className="recipient-info">
        <p className="paste-text">Enter who you want to send to: </p>
        <form className="search">
          <input
            type="text"
            className="address_search"
            id="address_search"
            required
            onChange={(e) => setTo(e.target.value)}
          />
          <br></br>
          <p>Enter amount:</p> <br></br>
          <input
            type="number"
            className="amount"
            id="amount"
            required
            onChange={(e) => setAmount(e.target.value)}
          />
          <br></br>
        </form>
        <button className="submit-send" onClick={sendBtn}>
          Send →
        </button>
      </div>
    </div>
  );
}
export default Send;
